<template>
  <v-card flat>
    <v-card-title class="">
      <div style="width: 420px">
        <v-img width="170" src="@/assets/logo.png" />
        COLLABORATION AGREEMENT BETWEEN
        <p class="font-weight-bold text-h5 ma-0">
          KOA IMPACT GHANA LIMITED <span class="headline">AND</span>
        </p>
        <p class="font-weight-bold text-h5 ma-0">COCOA FARMERS</p>
      </div>
      <v-spacer />
      <v-card width="180" height="180" color="grey">
        <v-img aspect-ratio="1" :src="farmer.picture" />
      </v-card>
    </v-card-title>
    <v-card-text class="mt-10">
      <p>
        This AGREEMENT is made on the day and year
        <span class="font-weight-bold body-1">{{
          dayjs(farmer.createdAt).format("dddd, DD MMM YYYY")
        }}</span>
        between Koa Impact Ghana Limited with its registered address of No. 52 Swaniker Street, Abelemkpe Accra, 
        Ghana (hereinafter referred to as “<span
          class="font-weight-bold"
          >THE COMPANY</span
        >”), and
      </p>
      <p>
        <span class="font-weight-bold"> {{ farmer.full_name }} </span>, a cocoa plantation farmer who earns his/her living from growing and selling cocoa beans and most recently cocoa pulp juice (hereinafter referred to as
        (hereinafter referred to as “<span class="font-weight-bold"
          >THE COCOA FARMER</span
        >”)
      </p>
      <p>
        both together referred to as “<span class="font-weight-bold"
          >the PARTIES</span
        >”
      </p>

      <h3 class="mt-10 mb-5">
        NOW THEREFORE the Parties hereto hereby agree as follows:
      </h3>
      <ol type="a">
        <li class="pl-5 ml-5">
          The Company sources the Cocoa Farmer&apos;s fresh cocoa beans and purchases all buckets that meet all in bound check specifications per agreed quantity and quality standards in accordance with the Company&apos;s policy. Solely, the cocoa pulp extracted from the beans are kept by the Company while the cocoa beans are returned to the Cocoa Farmer after the extraction. Where buckets are deemed to be rejected, will not be paid for as per this Agreement.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The price for each bucket will be communicated by the Company at the start of each crop season and may be subject to change when the need arises.
        </li>

        <li class="pl-5 ml-5 mt-1">
          The Company will make payments through its registered mobile money channel and the Cocoa Farmer shall ensure to maintain a working mobile money number for the payments.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The Cocoa Farmer agrees to bring the buckets of cocoa beans with the bean taxi riders to pressing site before 3pm on production days, if not instructed differently by the Company.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The Cocoa Farmer is expected to harvest the cocoa beans at most one day before breaking it.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The Cocoa Farmer will have to go through regular mandatory trainings and farm assessments 
          as well as strict adherence to hygiene and sustainable agricultural practices throughout 
          the duration of the collaboration. To ensure adherence with the Company&apos;s zero deforestation 
          policy and compliance with related industry regulations, the Cocoa Farmer consents that the 
          Company may map the geospatial data of his/her farms as part of the farm assessment to ensure 
          no cocoa is sourced from protected forest areas. In case of non-adherence to these protocols, 
          the Company reserves the right to halt the collaboration with the Cocoa Farmer without prior notice.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The Cocoa Farmer consents that the Company collects, stores and processes personal data 
          (such as name, photo, gender, community name, etc.) required for internal purposes including 
          but not limited to production planning and payments. The Cocoa Farmer may request at any time from 
          the Company a copy of his/her records as well as request updates or rectifications to the records where applicable.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The Cocoa Farmer also consents that the Company shares a subset of personal data, specifically, first name, 
          first letter of surname, gender, age and community name, as well as production data including number of buckets, 
          production time, payment amount and mobile money number to data processing providers, customers and end-consumers 
          for traceability purposes.
        </li>
        <li class="pl-5 ml-5 mt-1">
          In the event of this Agreement&apos;s termination, the Cocoa Farmer may request the Company to delete all records 
          of his/her personal data except the data whose retention is required for the Company&apos;s accounting purposes or authorized by law.
        </li>
      </ol>
      <h3 class="mt-10 mb-5">Duration of Agreement</h3>
      <ol type="a" start="10">
        <li class="pl-5 ml-5 mt-1">
          This agreement is subject to performance review by both parties.
        </li>
        <li class="pl-5 ml-5 mt-1">
          This agreement supersedes all prior agreements and understandings,
          oral, written and implied between both parties hereto with respect to
          the subject matter hereof.
        </li>
        <li class="pl-5 ml-5 mt-1">
          The Company or the Cocoa Farmer will have to give notice in
          writing/phone call one-month (30 days&apos;) notice before terminating the
          agreement.
        </li>
      </ol>

      <v-row class="mt-10">
        <v-col cols="6">
          <h3>THE COMPANY</h3>
        </v-col>
        <v-col cols="6">
          <h3>THE COCOA FARMER</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="">KOA IMPACT GHANA LIMITED</p>
          <p class="">52 Swaniker Road, Sal Heights</p>
          <p class="">P.O.Box AH 222 Achimota Accra</p>
          <p class="">Phone Number: 0201833494</p>
        </v-col>
        <v-col>
          <p class="text-uppercase">Name: {{ farmer.full_name }}</p>
          <p class="text-uppercase">Gender: {{ farmer.gender }}</p>
          <p class="text-uppercase">Phone Number: {{ farmer.phone }}</p>
          <p class="text-uppercase">Mobile Money: {{ farmer.momo }}</p>
          <p class="text-uppercase">Farmer ID: {{ farmer.farmer_id }}</p>
          <p class="text-uppercase">Farmer Community: {{ farmer.community }}</p>
          <p class="text-uppercase">
            Farmer Region: {{ farmer.region.region }}
          </p>
        </v-col>
      </v-row>
      <v-row align="end" justify="start">
        <v-col cols="6" class="text-left">
          <v-img
            :src="require('@/assets/unnamed.png')"
            aspect-ratio="4"
            width="142"
          />
          <v-divider />
          <p class="font-weight-bold">Signature</p>
        </v-col>
        <v-col cols="6">
          <!-- <qriously :value="farmer._id" :foreground="$vuetify.theme.dark ? 'white' : 'black'" :size="120"/> -->
          <v-card width="142" height="" color="grey">
            <v-img aspect-ratio="4" :src="farmer.fingerprint_picture" />
          </v-card>
          <v-divider />
          <p class="font-weight-bold">Signature/ Finger Print/QR Code</p>
        </v-col>
      </v-row>
      <p class="mt-10 font-weight-bold">
        KOA IMPACT GHANA LIMITED FARMERS COLLABORATION AGREEMENT
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "AgreementContent",
  props: {
    farmer: Object,
  },
  data() {
    return {
      dayjs,
    };
  },
  created() {
    console.log(this.farmer);
  },
};

</script>

<style scoped>
</style>
